import React from "react";
import Layout from "../components/layout";
import hotel from "../images/Haus.png";
import ausfluege from "../images/ausflug.jpg";
import feedback from "../images/feedback.jpg";
import news from "../images/News.png";
import kontakt from "../images/Kontakt.png";
import restaurants from "../images/restaurants.jpg";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import "../css/haw.css";


import {
useSpring,
animated,
config
} from 'react-spring';
{

/**/
}

function IndexPage() {



const animation0 = useSpring({
opacity: 1,
from: {
opacity: 0.9
},
delay: 0,
config: config.slow
})
const animation1 = useSpring({
opacity: 1,
from: {
opacity: 0.9
},
delay: 0,
config: config.slow
})
const animation2 = useSpring({
opacity: 1,
from: {
opacity: 0.9
},
delay: 0,
config: config.slow
})
return ( <Layout>       
     <section className="grid grid-cols-2">
     < AniLink  to="hotel" className="m-2">
                    < animated.div className="card" style={ animation1 }>
                      < img className="card2" src={ kontakt } />
                      </animated.div>
                      </AniLink>   
              
                  < AniLink  to="restaurants" className="m-2">
                    < animated.div className="card" style={ animation1 }>
                      < img className="card2" src={ restaurants } />
                      </animated.div>
                      </AniLink>                      
                       <AniLink  to="ausfluege" className="m-2">
                        < animated.div className="card" style={ animation2 }>
                          < img className="card2" src={ ausfluege } />
                          </animated.div>
                          </AniLink> 
                          <AniLink to="news" className="m-2">
                    < animated.div className="card" style={ animation1 }>                    
                      < img className="card2" id="" src={ news } />                      
                      </animated.div>
                </AniLink>    
                          </section>
                          <section className="grid">
      <AniLink  to="galerie" className="m-2">

    <animated.div className="card " style={ animation0 }>
    <img className="card2" src={ hotel } />
    </animated.div> </AniLink>    
    </section>
    < section className="grid grid-cols-2">                       
                      <AniLink  to="feedback" className="m-2">
                        < animated.div className="card" style={ animation2 }>
                          < img className="card2" src={ feedback } />
                          </animated.div>
                          </AniLink> 
                          </section>
                            </Layout>
                                             
                                           );
                            
                             } export default IndexPage;